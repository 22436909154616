import React, { useState, useEffect, useRef } from 'react'
import '../inward/inwarddbstatus.css';
import InwardContainerHeader from './InwardContainerHeader';
import InwardLogService from '../../services/inwardlog.service';
import {Link, useNavigate } from 'react-router-dom'; 
import SignatureCanvas from 'react-signature-canvas';
import InwardDeliverySheet from '../../services/inwarddeliverysheet.service';
import moment from 'moment'; 
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2';
import Select from 'react-select';
import MasterDataService from "../../services/masterData.service";
import AsyncSelect from 'react-select/async';
import Inwarddetailsercice from '../../services/inwarddetail.service';
const Inwardbulkupdate = () => {  
  

    // const [drsNumber, setdrsNumber] = useState()
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [drsId, setdrsId] = useState(0);
    const [drsDetails, setDrsDetails] = useState([]);
    // const [selectedRecords, setSelectedRecords] = useState();
    const [redirectempId,setredirectempId] = useState(0)
    const [redirectempName,setredirectempName] = useState("")
    const [employeeList, setemployeeList] = useState([]);
    const [emptab,setemptab] = useState(false);
    const [rowcolor,setrowcolor] = useState("#FFFFFF");
    

   
    


    const handleRowClick = (drsIdRow: number) => {
        setrowcolor("00BFFF");
        setdrsId(drsIdRow)
        InwardDeliverySheet.getDrsDetailsbyId(drsIdRow).then(
            (data) => {
                // console.log("data", data)
                if(data)
                {
                    setDrsDetails(data);
                }
               
            }
        ).catch((error) => {
            console.log(error);
            console.log("server responding");
        })
    };
    const [inwarddetailStatus, setinwarddetailStatus] = useState(0);

    const [receivedBy, setreceivedBy] = useState('');
    const [remark, setremark] = useState('');
    const [inwardDate, setinwardDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
    const [inwardTime, setinwardTime] = useState((moment(new Date()).utcOffset('+0530')).format('HH:mm'));
     const [showSign, setShowSign] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [statusReasons, setstatusReasons] = useState([]);
    const [inwarddetailList, setinwarddetailList] = useState([]);
    const [ReasonId, setReasonId] = useState(0);
    const [ReasonLabel, setReasonLabel] = useState('');
    
   const handleOptionChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setinwarddetailStatus(Number(event.target.value));  
        if (event.target.value === '8') {
            setShowSign(true)
        } else {
            setShowSign(false)
        }
        // console.log("inwarddetailStatus", inwarddetailStatus)
        if (event.target.value === '9') { setReasonId(0)}
        if (event.target.value === '13') {
            setemptab(true)
          } else {
            setemptab(false)
          }
    };
    const signatureCanvasRef = useRef<any>(null);



//for filters new
const [allInwardDetails, setAllInwardDetails] = useState([]);
const [EmployeeList, setEmployeeList] = useState([]);
const [Employee, setEmployee] = useState('Employee Name');
const [EmployeeId, setEmployeeId] = useState(0);
const [AWBNo, setAWBNo] = useState('AWB No'); 
const [StatusList, setStatusList] = useState<any | []>([]);
const [StatusId, setStatusId] = useState(0);
const [Status, setStatus] = useState('Pending');
const [Fromdate, setFromdate] = useState((moment(new Date())).format('YYYY-MM-DD'));
const [Todate, setTodate] = useState((moment(new Date())).format('YYYY-MM-DD'));
const [ModeList, setModeList] = useState([]);
const [Mode, setMode] = useState('Select Mode');
const [ModeId, setModeId] = useState(0);
const [RouteList, setRouteList] = useState([]);
const [Route, setRoute] = useState('Select Route');
const [RouteId, setRouteId] = useState(0);
const [DepartmentList, setDepartmentList] = useState([]);
const [Department, setDepartment] = useState('Select Department');
const [DepartmentId, setDepartmentId] = useState(0);
const [totalInward, settotalInward] = useState(0);
const [totalPcs, settotalPcs] = useState(0);


  useEffect(()=>{
    getEmployee();
    getMode();
    getDepartment();
    getRoute();
    getReportType();
  },[]);

  const getReportType = () => {
           
    const options =  [
    {
        "value": 0,
        "label": "Pending"
    },
  ]
      setStatusList(options)
      setStatusId(options[0]['value'])
      setStatus(options[0]['label'])
}

useEffect(() => {
  getInwardDetailList();
 }, [Fromdate,Todate,EmployeeId,StatusId,AWBNo,ModeId,RouteId,DepartmentId])
 useEffect(() => {
   setFromdate((moment(new Date())).format('YYYY-MM-DD'))
 }, [])


 const getRoute = async () => {
  await MasterDataService.getRouteId().then(
    async (data) => {
      const options = data.map((d: any) => ({
        "value": d.id,
        "label": d.routeName
      }))
      options.unshift({ value: 0, label: "Select Mode" });
      await setRouteList(options)
   }
  )
}

const getDepartment = async () => {
  await MasterDataService.getDepartment().then(
    async (data) => {
      if(data){
      const options = data.map((d: any) => ({
        "value": d.id,
        "label": d.name
      }))
      options.unshift({ value: 0, label: "Select Department" });
      await setDepartmentList(options)
     }
   }
  )
}


 const getMode = () => {
  MasterDataService.GetshipmentMode().then(
      async (data) => {
        if(data) {            
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.modeName
        }))
        options.unshift({ value: 0, label: "Select Mode" });
        await setModeList(options)
      }
      }
  );
}

 const getEmployee = () => {
   MasterDataService.getEmployeeList().then(
       async (data) => {
         if(data) {            
         const options = data.map((d: any) => ({
           "value": d.id,
           "label": d.employeeName
         }))
         options.unshift({ value: 0, label: "Select Employee" });
         setEmployeeList(options)
         setEmployeeId(0)
         setEmployee("All Employee")
       }
       }
   );
 }

 const getInwardDetailList = async () => {
  await Inwarddetailsercice.getdataforhdtracking(Fromdate,Todate,EmployeeId,StatusId,AWBNo,ModeId,DepartmentId,RouteId).then(
    (data) => {
      if (data) {
        setAllInwardDetails(data);
      }
    }
  );
}

  const handleInputChangeAwb = async (input: any) => {
    if (input.length > 3) {
      return await InwardDeliverySheet.getAwbNumberbyAutoComplte(input).then(
        (data) => {
          const awbNumberList = data.map((d: any) => ({
            "value": d.AWB,
            "label": d.AWB
          }))
          return awbNumberList;
        }
      )
    }
  };
const handleEmployee = (e:any) => {
  console.log(JSON.stringify(e));
  setEmployeeId(e.value);
  setEmployee(e.label)
}
const handleStatus = (e:any) => {
  setStatusId(e.value);
  setStatus(e.label)
}

const handleMode = (e:any) => {
  setModeId(e.value);
  setMode(e.label)
}

const handleDepartment = (e:any) => {
  setDepartmentId(e.value);
  setDepartment(e.label)
}

const handleRoute = (e:any) => {
  setRouteId(e.value);
  setRoute(e.label)
}

//end filters new


    useEffect(() => {
        getAllEmployee()
      }, [inwarddetailStatus])
      
      const getAllEmployee = async () => {
        await MasterDataService.getEmployeeList().then(
            (data) => {
                if (data) {
                    const options = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.employeeName
                    }))
                    setemployeeList(options);
                    // setallSelections(options)
                }
            }
        );
    }
 
    const HandleSubmit = () => {
        if((inwarddetailStatus !== null) && (inwarddetailList.length !== 0)){
      let  deliverySignbase64 = "";
        if (signatureCanvasRef.current) {
           deliverySignbase64 = signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
            // setdeliverySign(signatureImage);
        }  
    // console.log("deliverySignbase64", deliverySignbase64);
    
        const inwarddetailid = inwarddetailList.map((inwdId : any) => {
            return inwdId.id
        })
// inwarddetailid.push(inwardId)
        InwardLogService.postupdateinwarddetailstatus(inwarddetailid,inwarddetailStatus, receivedBy, remark, inwardDate, inwardTime, deliverySignbase64, ReasonId, ReasonLabel, drsId,redirectempId,redirectempName).then(
            (data) => {
if(data){
    Swal.fire({
        position: 'center',
        icon: 'success',
        title: "Inward Status Updated Successfully",
        showConfirmButton: true,
        timer: 1500,
        customClass: {
          title: 'swal-fire-alert', // Add your custom CSS class here
        },
      }).then(function () {
       window.location.reload();
        // navigate('../inworddetailslist');
      });
}
                // console.log(data.statuscode)
                // if (data.statuscode === 200) {
                //     setinwarddetailStatus(data.message[0].inwarddetailStatus);
                // }
            }
        ).catch((error) => {
            console.log(error);
            console.log("server responding");
        })
    }
    }
   
    const clearSignature = () => {
        if (signatureCanvasRef.current) {
            signatureCanvasRef.current.clear();
        }
    }

    useEffect(()=>{
        getRtoReasons()  
    },[inwarddetailStatus])
    const getRtoReasons = () => {
        let inwarddetailStatusresponce = 0;
      if(inwarddetailStatus === 9){
        inwarddetailStatusresponce = 9
      }
        InwardDeliverySheet.getRtoReasons(inwarddetailStatusresponce, 1).then(
            (data)=>{
                if(data){
                    const options = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.returnReason
                      }))
                    setstatusReasons(options)
                }
            }
        ).catch((error) => {
            console.log(error);
            console.log("server responding");
        })
    }
   
    interface selectRowType {
        mode: any;
        clickToSelect: boolean;  
        style: any;
        onSelect: any;    
        onSelectAll :any
      }    
      
      const selectRow: selectRowType = {
        mode: "checkbox",
        clickToSelect: true,
        onSelect: (row :never, isSelect :any, rowIndex :any, e:any) => {
            if(isSelect){ 
                setinwarddetailList([...inwarddetailList, row]) 
            } else {
                const secondIndex = inwarddetailList.findIndex((Selectrow :any) => Selectrow['id'] === row['id']);
                inwarddetailList.splice(secondIndex, 1);
                console.log("inwarddetailList", inwarddetailList)
             }
            
          },
          onSelectAll: async (isSelect :any, rows :any, e :any) => {
            if(isSelect){
             var IdArray :[] = [];
             rows.map(async (rowDatas:never)=>{
                 IdArray.push(rowDatas) ;
             })
             await setinwarddetailList(IdArray)
            }else {
            await setinwarddetailList([])
            }
            
           },
        style: (row :any, rowIndex:any) => {
        const backgroundColor = rowIndex >= 0 ? '#00BFFF' : '#00FFFF';
        return { backgroundColor };
        }
      }

      
      const dateformater = (data: any, row: any) => {
        return  (moment(data)).format('DD/MM/YYYY')
      }
      const columns: ColumnDescription<selectRowType>[] = [
        // { dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
        //   return rowIndex + 1;
        // // return cell;
        // }, text: 'Sr No',  align: 'center'},
        { dataField: 'AWB', text: 'AWB No' },
        { dataField: 'inwardDate', text: 'Inward Date', headerAlign: 'left',// formatter : dateformater
        formatter: (cell, row :any, rowIndex, formatExtraData) => {
            return row['inwardDate']  + ' ' + row['inwardTime'] ;
          // return cell;
          },
        },
        // { dataField: 'inwardType', text: 'Type', headerAlign: 'left', style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }, classes:"showinline" },
        { dataField: 'inwardQuantity', text: 'Pcs.',  align: 'center'},
        { dataField: 'consignerName', text: 'Consignor',  align: 'left'},
        { dataField: 'consignerCityName', text: 'City',  align: 'left'},
        { dataField: 'employeeName', text: 'Employee',  align: 'left'},
        { dataField: 'departmentName', text: 'Department',  align: 'left'},
         { dataField: 'serviceProviderName', text: 'Service Provider',  align: 'left'},
        { dataField: 'inwarddetailStatusDescription', text: 'Status',  align: 'left'},
        { dataField: 'remark', text: 'Remark',  align: 'left'},
        { dataField: 'shipmentModeName', text: 'Mode',  align: 'left'},
       
    
      ];
    const filteredData = Array.isArray(allInwardDetails)
    ? allInwardDetails.filter((selectedRecords) => {
        const values = Object.values(selectedRecords);
        return values.some(
          (value: unknown): boolean => {
            if (typeof value === 'string' || typeof value === 'number') {
              const stringValue = String(value).toLowerCase();
              return stringValue.includes(searchQuery.toLowerCase());
            }
            return false;
          }
        );
      })
    : [];  
 

    useEffect(()=>{
      settotalInward(inwarddetailList.length)
    },[inwarddetailList])
    return (
        <>
            <InwardContainerHeader />
            <div className='mb-10'>
                <div style={{ margin: "0px" }}>
                    <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
                        <div className="container col-11" style={{padding:'0px'}}> 
                            <div className="themeColor width" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
  <p style={{ marginLeft: "10px" }}>Update Inward Status </p>
                            <div className='insameline'>
                          <input type='date' className='col-6 zeroPadding' value={Fromdate} max={moment().format("YYYY-MM-DD")} name='fromdate' onChange={e => setFromdate(e.target.value)} />
                          <input type='date' className='col-6 zeroPadding' value={Todate} max={moment().format("YYYY-MM-DD")} name='todate' onChange={e => setTodate(e.target.value)} />
                        </div>
                             </div>                              
                             <div className='col-12 inline' style={{padding:'0px'}}>
                             <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly", paddingTop: '1%' }} >
      
               
              
            </div>
            </div> 
            <div className='col-12 inline' style={{padding:'7px'}}>
            <div style={{ display: "flex", flexDirection: "row", width: "100%",   justifyContent: "space-evenly" }} >
            
            <div className='col-7 row'>
              <div className='col-3' >
              <Select name="ModeId"
                      onChange={handleMode}
                      options={ModeList}
                      value={{ value: ModeId, label: Mode }}
                    />
             
              </div>
              <div className='col-4' >
              <AsyncSelect name="AwbNo"
                          onChange={(e: any) => {setAWBNo(e.value)}}
                          loadOptions={handleInputChangeAwb}
                          value={{ value: AWBNo, label: AWBNo }}
                          className='col-12'
                        />
              </div>
              <div className='col-5' >
             <Select name="EmployeeId"
                      onChange={handleEmployee}
                      options={EmployeeList}
                      value={{ value: EmployeeId, label: Employee }}
                    />
              </div>
              </div>
              <div className='col-5 row'> 
             
              <div className='col-4' >
              <Select name="departmentId"
                      onChange={handleDepartment}
                      options={DepartmentList}
                      value={{ value: DepartmentId, label: Department }}
                   />
              </div>
              <div className='col-4' >
              <Select name="routeId"
                      onChange={handleRoute}
                      options={RouteList}
                      value={{ value: RouteId, label: Route }}
                   />
              </div>
              <div  className='col-4'>
              <input
                  className='searchbar col-12'
                  type='text'
                  value={searchQuery}
                  placeholder = 'Search Here ...'
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              </div>
            </div></div>
            
                                <div className='col-12'>
                                <div className="table-responsive tableContainer">
                            <BootstrapTable
                                keyField='id'
                                striped
                                hover
                                condensed
                                data={filteredData}
                                filter={filterFactory()}
                                filterPosition="top"
                                columns={ columns }
                                selectRow={ selectRow }
                                />
                            </div>
                                </div>


                        <div className='col-12'> Total Selected Records : <span style={{color:'green'}}>  {totalInward} </span> 
                        {/* <span>  Total Pcs. : <span style={{color:'green'}}>{totalPcs} </span>  </span>  */}
                         </div>
                        <div className='card container backgroundColor'>
                            <div className='' > 
                        <div className='col-12 inline'>
                                            <label className='Radiobtn col-2 inline'><input type='radio' className='radio-btn' 
                                                value="8"
                                                name="deliveryStatus" 
                                                checked={inwarddetailStatus === 8}
                                                onChange={handleOptionChange1}

                                            /> Delivered</label>

                                        <label className='Radiobtn col-2 inline'><input type='radio'className='radio-btn'
                                                value="9"
                                                name="deliveryStatus" 
                                                checked={inwarddetailStatus === 9}
                                                onChange={handleOptionChange1}

                                            /> Undelivered</label>


                                            <label className='Radiobtn col-2'> <input type='radio'className='radio-btn'
                                                value="13"
                                                name="deliveryStatus" 
                                                checked={inwarddetailStatus === 13}
                                                onChange={handleOptionChange1}
                                            /> Redirected</label>

                                            <label className='Radiobtn col-2'>
                                                 <input type='radio'className='radio-btn'
                                                value="14"
                                                name="deliveryStatus" 
                                                checked={inwarddetailStatus === 14}
                                                onChange={handleOptionChange1}
                                            /> Unclaimed</label>
<label className='Radiobtn col-2'> <input type='radio'className='radio-btn'
                                                value="16"
                                                name="deliveryStatus" 
                                                checked={inwarddetailStatus === 16}
                                                onChange={handleOptionChange1}
                                            /> OnHold</label>
                                     
                                     <div className='col-12 inline'>
                                            {/* <label className='Radiobtn col-4'><input type='radio'className='radio-btn'
                                                value="15"
                                                name="deliveryStatus" 
                                                checked={inwarddetailStatus === 15}
                                                onChange={handleOptionChange1}
                                            /> Reassign</label> */}
                                            {/* <label className='Radiobtn col-4'> <input type='radio'className='radio-btn'
                                                value="9"
                                                name="deliveryStatus" 
                                                checked={inwarddetailStatus === 9}
                                                onChange={handleOptionChange1}
                                            /> </label> */}
                                            
</div> 
</div > 
                            </div>
                            <div className='col-12 inline' style={{minHeight:200}}>
                            <div className='col-8'>
                           
                          {(statusReasons.length !== 0) && (
                          <div className='col-12 inline' style={{marginTop : '10px'}}>
                                <label className='col-4'>Reason </label>
                                <Select
                                        name="ReasonId"
                                        onChange={(e:any)=>{setReasonId(e.value); setReasonLabel(e.label) }}
                                        options={statusReasons}
                                        value={{ value: ReasonId, label: ReasonLabel }}
                                        className='col-8'
                                        />
                                {/* <input className='col-8'type="text"   value={remark} name='remark'onChange={e => setremark(e.target.value)} /> */}
                          </div>)}
                          {emptab && (
                            <div className='col-12 inline' style={{marginTop : '10px'}}>
                            <label className='col-4'>Redirect To </label>
                            <Select
                                    name="employee"
                                    onChange={(e: any) => { setredirectempId(e.value); setredirectempName(e.label) }}
                                    options={employeeList}
                                    value={{ value: redirectempId, label: redirectempName }}
                                    className='col-8'
                                    />
                        </div>
                      )}
                          
                          {showSign && (<div className='col-12 inline' style={{marginTop : '10px'}}>
                                    <label className='col-4'>Received By</label>
                                        <input className='col-8'  type="text"
                                            value={receivedBy} name='receivedBy' onChange={e => setreceivedBy(e.target.value)} />
                                    </div>)}
                                <div className='col-12 inline' style={{marginTop : '10px'}}>
                                        <label className='col-4'>Status Date & Time</label>
                                        <input className='col-4' type="date"
                                            value={inwardDate} name='inwardDate' onChange={e => setinwardDate(e.target.value)} />
                                        <input className='col-4' type="time" style={{}}
                                            value={inwardTime} name='inwardTime' onChange={e => setinwardTime(e.target.value)} />
                                    </div>
                                    
                          <div className='col-12 inline' style={{marginTop : '10px'}}>
                                <label className='col-4'>Remark</label>
                                <input className='col-8'type="text"   value={remark} name='remark'onChange={e => setremark(e.target.value)} />
                          </div>
                            </div>
                            <div className='col-4'>
                                {showSign && (
                                <div >
                                   <h5>Sign Box</h5>
                                    <div>
                                        <SignatureCanvas
                                            ref={signatureCanvasRef}
                                            penColor="black"
                                            canvasProps={{ width: 300, height: 100, className: 'sigCanvas border' }}
                                        // canvasProps={{ width, height }}
                                        />
                                        <div>
                                            {/* <button className='btn2 col-6' onClick={saveSignature}>Save Signature</button> */}
                                            <button className='btn2 col-6' onClick={clearSignature}>Clear</button>
                                        </div>
                                    </div>                                   
                                </div>
                                )}   
                                
                            </div>
                            </div>

                           
                        </div>
                        <div className='btn d-flex justify-content-center'>
                            <button className="btn2 col-2 col-sm-1" type="button" onClick={HandleSubmit}><i className="fa fa-save"></i> Save</button> 
                            <button className="btn2 col-2 col-sm-1" > <i className="fa fa-close" ></i> Clear</button>
                            <Link className="btn2 col-2 col-sm-1" to="/inwardloglist" type='button'>  <i className="fa fa-sign-out"></i> Close </Link>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>

    )
}

export default Inwardbulkupdate