import  { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'
import moment from 'moment';
import InwardLogService from '../../services/inwardlog.service';
import InwardContainerHeader from './InwardContainerHeader';

function Inwardloglist() {


  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [logDateSearch, setlogDateSearch] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [allInwardLog, setAllInwardLog] = useState([]);
  const addButtonRef = useRef<any>(null);
  const[totalInwards, setTotalInwards] = useState(0)
  const[totalPcs, setTotalPcs] = useState(0)
  const[totalPending, setTotalPending] = useState(0)
  const dateformater = (data: any, row: any) => {
    return  (moment(data)).format('DD/MM/YYYY')
  }
  const timeformater = (data :any, row: any) => {
    if(data.length <= 8) {
      return (data)
    }else {
    const parsedDate = moment(data, "YYYY-MM-DD@HH:mm:ss");
    return (parsedDate.format('hh:mm A'))
    }
  }
  interface selectRowType {
    mode: any;
    clickToSelect: boolean;  
    style: any;
    onSelect: any;
  }

  const selectRow: selectRowType = {
    mode: "radio",
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row: any) => {
      setSelectedRecords(row.id);
    }
  }
  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-red text-white'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };

  const pagination = paginationFactory(options);
  const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    }, text: 'Sr. No',  align: 'center'},
    { dataField: 'inwardDate', text: 'Log Date', sort: true, // formatter: dateformater,

     },
    { dataField: 'inwardTime', text: 'Log Time' },
    { dataField: 'batchNo', text: 'Batch Number' },
    { dataField: 'shipmentModeName', text: 'Mode Name', headerAlign: 'left'},
    { dataField: 'combinedColumn', text: 'Service Provider /Branch', headerAlign: 'left', style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }, classes:"showinline" ,  formatter: (cell, row :any) =>  `${row['serviceProviderName']} ${row['fromBranchName']}`},
    { dataField: 'noOfShipment', text: 'Pcs.',  align: 'center' , style : { fontWeight :'bold', width : "75px"} },
    { dataField: 'totalEntriesDone', text: 'Entry Done',  align: 'center',style : {color : 'green', fontWeight :'bold', width : "75px"} },
    { dataField: 'pendingQuantity', text: 'Pending Entry',  align: 'center', style : {color : 'red', fontWeight :'bold', width : "75px"} },
    { dataField: 'logRemarks', text: 'Remark', style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }, classes:"showinline" },

  ];
  //Table End
  const getInwardLogList = async () => {
    await InwardLogService.getInwardLogListdateWise(logDateSearch).then(
      (data) => {
        if (data) {
          setAllInwardLog(data);
        }
      }
    );
  }
  useEffect(() => {
    getInwardLogList();
  }, [logDateSearch])
  useEffect(() => {
    setlogDateSearch((moment(new Date())).format('YYYY-MM-DD'));
    if (addButtonRef.current !== null) {
      addButtonRef.current.focus();
    }
  }, [])

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const HandleDelete = () => {
    if (selectedRecords !== undefined) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          InwardLogService.deleteinwardLog(selectedRecords)
            .catch((error) => {
              console.log(error);
              console.log("server responding");

            })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Inward Log has been deleted",
          }).then(function () {
            window.location.reload()
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Inward Log is safe :)",
            }).then(function () {
              window.location.reload()
            }
            )
        }
      })
    }
  }

  const navigate = useNavigate();
  const EditDelivery = () => {
    if (selectedRecords !== undefined) {
      navigate('../inwardlog', { state: selectedRecords });
    }
  }

  const filteredData = Array.isArray(allInwardLog)
  ? allInwardLog.filter((selectedRecords) => {
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): boolean => {
          if (typeof value === 'string' || typeof value === 'number') {
            const stringValue = String(value).toLowerCase();
            return stringValue.includes(searchQuery.toLowerCase());
          }
          return false;
        }
      );
    })
  : [];

  useEffect(() => { 
    const sum = filteredData.reduce((acc, item:any) => acc + (item.noOfShipment || 0), 0);
    setTotalInwards(sum);
    const sumEntry = filteredData.reduce((acc, item:any) => acc + (item.totalEntriesDone || 0), 0);
    setTotalPcs(sumEntry);
    const pendingSum = filteredData 
    .reduce((acc, item:any) => acc + (item.pendingQuantity || 0), 0);
  setTotalPending(pendingSum); // Set the sum of pending items
   
  
  }, [filteredData]); // Run effect when `filteredData` changes 

  return (
    <>
      <InwardContainerHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
          <div className="container col-11" style={{ paddingLeft: "0px" }}>
            <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Inward Log List</p></div>

            <div className='col-12' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "space-evenly", paddingTop: '1%' }}>

              <div className='margincard1itams' style={{ marginLeft: "10px" }}>
                <label> Log Date</label>
                <input className='margininutleft' type='Date' value={logDateSearch} max={moment().format("YYYY-MM-DD")} name="logDateSearch" onChange={(e) => { setlogDateSearch(e.target.value) }} />
              </div>
              <div className='margincard1itams'>
                <label >Branch Name :  {(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branchName : ""} </label>
              </div>
              <div className='col-6' style={{ display: "flex", height: "50px", justifyContent: "end" }}>
                <input
                  className='searchbar col-6'
                  type='text'
                  value={searchQuery}
                  placeholder='Search Here ...'
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="table-responsive tableContainer">
            <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%'}}
              keyField='id'
              striped
              hover
              condensed
              data={filteredData}
              columns={columns}
              // pagination={pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />
            </div>
          </div>
          <div className='row col-12 d-flex justify-content-center'>
            <div className='col-5' style={{fontWeight :'bold'}}>
            <span className='col-1'> Pcs : <span>{totalInwards} </span> </span>
            <span className='col-1' > Entry Done. : <span style={{color: 'green'}}>{totalPcs}</span></span> 
            <span className='col-1'> Pending : <span style={{color: 'red'}}>{totalPending}</span></span>
            </div>
            <div className='row col-7 d-flex justify-content-center'>
            <Link to="../inwardlog" className="btn2 col-2" ref={addButtonRef}  > <i className="fa fa-plus" aria-hidden="true"></i>Add</Link>
            <button className="btn2 col-2" onClick={EditDelivery}><i className="fa fa-edit"></i> Edit</button>
            <button className="btn2 col-2" onClick={HandleDelete} ><i className="fa fa-trash"></i> Delete</button>
            <button className="btn2 col-2" type="submit"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Inwardloglist