import '../headerfooter/headerfootercss.css';
import Swal from 'sweetalert2';

const Footer = () => {
  const showDialogforhotkey = () => {
    // Add logic to show dialog for hotkey
    Swal.fire({
      position: 'bottom-end',
      icon: 'info',
      title: 'HotKey Details',
      html: `
        <div style="display: flex; font-family: Arial, sans-serif;">
          <div style="flex: 1; text-align: left; padding: 10px;">
            <h4 style="margin-bottom: 10px;">Inward </h4>
            <ul style="list-style-type: none; padding-left: 0;">
              <li><span style="font-weight: bold;">Alt + i:</span> Inward Log List</li>
              <li><span style="font-weight: bold;">Alt + l:</span> Inward Log Entry</li>
              <li><span style="font-weight: bold;">Alt + n:</span> Inward List</li>
              <li><span style="font-weight: bold;">Alt + u:</span> Inward Entry</li>
              <li><span style="font-weight: bold;">Alt + d:</span> DRS Entry</li>
            </ul>
          </div>
          <div style="flex: 1; text-align: left; padding: 10px;">
            <h4 style="margin-bottom: 10px;">Outward</h4>
            <ul style="list-style-type: none; padding-left: 0;">
              <li><span style="font-weight: bold;">Alt + o:</span> Outward Log List</li>
              <li><span style="font-weight: bold;">Alt + j:</span> Out Log Entry</li>
              <li><span style="font-weight: bold;">Alt + p:</span> Outward List</li>
              <li><span style="font-weight: bold;">Alt + k:</span> Outward Entry</li>
              <li><span style="font-weight: bold;">Alt + m:</span> Manifest Entry</li>
            </ul>
          </div>
        </div>
      `,
      showConfirmButton: true,
      timerProgressBar: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK'
    });
    
  };
  return (
    <>
   <div style={{ position: "fixed", bottom: 0, width: "100%",  zIndex: 1 }}>
  <div className="d-flex justify-content-between">
    <div className="col-8">MailRoom By &nbsp; <a href='https://mailit.co.in' target='_blank' style={{ color: 'rgb(196, 21, 21)' }}>Mailit MailRoom Managment Services Pvt. Ltd. </a> &nbsp;| {(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).mailRoomName : ""} | {(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branchName : ""}</div>
    <div className="col-3"> Maintained By &nbsp; <a href='https://sanyogsoft.com/' target='_blank' style={{ color: "orangered" }}> Sanyog Software </a> </div>
    <div className="col-1"> <a onClick={showDialogforhotkey}>Hotkey <i className='fa fa-info'></i></a></div>
  </div>  
   <div className="line"></div>
  
</div>
    {/* <div style={{position: "fixed", bottom: "0", width: "100%", backgroundColor : "#fff", zIndex :1, borderTop : "1px solid #ccc" }} >  
    </div>     */}
    </>
  )
}

export default Footer