import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine   } from 'recharts';

function DatewiseTable(props:any ) {
  // Preparing the data for the chart
  const chartData = []; 
  const today = new Date(); // Get today's date
  const todayDay = today.getDate(); // Get the day of the month
  // Create data points for each day of the month (1 to lastDate)
  for (let i = 1; i <= props.lastDate; i++) {
    const dayData :any = { day: i }; // Key for the X-axis

    // Adding counts for each mode
    props.dateWiseData.forEach((d:any) => {
      dayData[d.mode] = d.dates[i - 1]; // Assuming `dates` is an array of counts
    });

    chartData.push(dayData);
  }
  const formatMonthYear = (selectedMonth:any, selectedYear:any) => {
    // Create a new date object with the selected month and year
    const date = new Date(selectedYear, selectedMonth - 1, 1); // `selectedMonth - 1` to align with zero-based month index
  
    // Extract the full month name and concatenate with the year
    const monthName = date.toLocaleString('default', { month: 'long' }); // Full month name
    return `${monthName}-${selectedYear}`; // Concatenate month name and year
  };
  return (
    <div style={{ width: '100%', height: '300px' }}>
       <ResponsiveContainer width="100%" height="95%">
    <LineChart 
      data={chartData} // Data for the chart
    >
      <CartesianGrid strokeDasharray="3 3" />  
      <XAxis dataKey="day"  label={{ value: `Days in `+ formatMonthYear(props.selectedMonth, props.selectedYear),  position: 'insideBottom', offset: -3, }} tickFormatter={(tick: any) => (tick === todayDay ? `*${tick}` : tick)}   />  
      <YAxis label={{ angle: -90, position: 'insideLeft', offset: 15, value: 'Inward Count' }} />  
      <Tooltip />  
      <Legend verticalAlign="top" />  

      {props.dateWiseData.map((d:any, index :number) => (
        <Line
          key={index}
          type="monotone" // Line type (monotone for smoother curves)
          dataKey={d.mode} // Use mode as the data key
          stroke={`hsl(${index * 50}, 70%, 50%)`} // Unique color for each line
          dot={true} // Optionally show dots at data points
        />
      ))}
       <ReferenceLine
            x={todayDay} // Position the reference line at today's day
            stroke="grey" // Highlight with red
            strokeWidth={2} // Bold stroke for emphasis
          />
    </LineChart>
    </ResponsiveContainer>
    </div>
  );
}

export default DatewiseTable;
