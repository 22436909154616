import axios from "axios";


const API_URL = process.env.REACT_APP_BASE_URL_API;
const token = `Bearer ` + localStorage.getItem('token');
// const createdBy =  localStorage.getItem('userId');
// const updatedBy =  localStorage.getItem('userId');
const mailRoomId =  localStorage.getItem('mailRoomId');
const branch =(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : 0;

const config = {
    headers: {
        'Authorization': token,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
};

const getDateWiseInwardShipment = async (selectedMonth:any,selectedYear:any) => {

    const bodyParamter = {
         month : selectedMonth ,
         year : selectedYear,    
         mailRoomId,
         branch
    };
    return axios
        .post(API_URL + "dashboard/inwardshipmentperday", bodyParamter, config).then(
            (response) => {
                if(response.data.statuscode === '200')
                return response.data.message;
               
            })
        .catch(error => {return ""});

}
const inwardshipmentmonthlycount = async () => {

    const bodyParamter = { 
         mailRoomId,
         branch
    };
    return axios
        .post(API_URL + "dashboard/inwardshipmentmonthlycount", bodyParamter, config).then(
            (response) => {
                if(response.data.statuscode === '200')
                return response.data.message;
               
            })
        .catch(error => {return ""});

}

const outwardshipmentmonthlycount =async (selectedMonth: any, selectedYear:any) =>{
    const bodyParamter = { 
        month : selectedMonth ,
        year : selectedYear,    
        mailRoomId,
        branch
   };
   return axios
       .post(API_URL + "dashboard/vendorwiseoutwardshipmentofmonth", bodyParamter, config).then(
           (response) => {
               if(response.data.statuscode === '200')
               return response.data.message;
              
           })
       .catch(error => {return ""});  
}



const getinwardstatusofmonth = async (selectedMonth:any,selectedYear:any) => {

    const bodyParamter = {
         month : selectedMonth ,
         year : selectedYear,    
         mailRoomId,
         branch
    };
    return axios
        .post(API_URL + "dashboard/inwardstatusofmonth", bodyParamter, config).then(
            (response) => {
                if(response.data.statuscode === '200')
                return response.data.message;
               
            })
        .catch(error => console.log(error));

}
const inwardshipmentofmonth = async (selectedMonth:any,selectedYear:any) => {

    const bodyParamter = {
         month : selectedMonth ,
         year : selectedYear,    
         mailRoomId,
         branch
    };
    return axios
        .post(API_URL + "dashboard/inwardshipmentofmonth", bodyParamter, config).then(
            (response) => {
                if(response.data.statuscode === '200')
                return response.data.message;
               
            })
        .catch(error => console.log(error));

}
const getDateWiseOutwardShipment = async (selectedMonth:any,selectedYear:any) => {

    const bodyParamter = {
         month : selectedMonth ,
         year : selectedYear,    
         mailRoomId,
         branch
    };
    return axios
        .post(API_URL + "dashboard/outwardshipmentperday", bodyParamter, config).then(
            (response) => {
                if(response.data.statuscode === '200')
                return response.data.message;
               
            })
        .catch(error => {return ""});

}
const getoutwardstatusofmonth = async (selectedMonth:any,selectedYear:any) => {

    const bodyParamter = {
         month : selectedMonth ,
         year : selectedYear,    
         mailRoomId,
         branch
    };
    return axios
        .post(API_URL + "dashboard/outwardstatusofmonth", bodyParamter, config).then(
            (response) => {
                if(response.data.statuscode === '200')
                return response.data.message;
               
            })
        .catch(error => console.log(error));

}
const outwardshipmentofmonth = async (selectedMonth:any,selectedYear:any) => {

    const bodyParamter = {
         month : selectedMonth ,
         year : selectedYear,    
         mailRoomId,
         branch
    };
    return axios
        .post(API_URL + "dashboard/outwardshipmentofmonth", bodyParamter, config).then(
            (response) => {
                if(response.data.statuscode === '200')
                return response.data.message;
               
            })
        .catch(error => console.log(error));

}

const DashboardDataService = {
    getDateWiseInwardShipment,
    inwardshipmentmonthlycount,
    outwardshipmentmonthlycount,
    getinwardstatusofmonth,
    inwardshipmentofmonth,
    getDateWiseOutwardShipment,
    getoutwardstatusofmonth,
    outwardshipmentofmonth,
}

export default DashboardDataService